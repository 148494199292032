import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Card, CardActions, CardContent, Grid, IconButton, Stack, Typography } from "@mui/material";
import { HiTemplate } from "react-icons/hi";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate } from 'react-router-dom';

export default function ManageCms() {
  const navigate = useNavigate()

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>CMS pages</Typography>
        </Stack>


        <Grid container spacing={1}>
          <Grid item xl={3} lg={3} >
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">About Us</Typography>
              </CardContent>
              <CardActions>
                <IconButton onClick={() => navigate("/edit-page/about-us")}><ArrowRightAltIcon color='primary' /></IconButton>
              </CardActions>
            </Card>

          </Grid>
          <Grid item xl={3} lg={3} >
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">Privacy Policy</Typography>
              </CardContent>
              <CardActions>
                <IconButton onClick={() => navigate("/edit-page/privacy-policy")}><ArrowRightAltIcon color='primary' /></IconButton>
              </CardActions>
            </Card>

          </Grid>
          <Grid item xl={3} lg={3} >
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">Terms & Condition</Typography>
              </CardContent>
              <CardActions>
                <IconButton onClick={() => navigate("/edit-page/terms-and-conditions")}><ArrowRightAltIcon color='primary' /></IconButton>
              </CardActions>
            </Card>

          </Grid>
          <Grid item xl={3} lg={3} >
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">Channel Terms & Condition</Typography>
              </CardContent>
              <CardActions>
                <IconButton onClick={() => navigate("/edit-page/channel/terms-and-conditions")}><ArrowRightAltIcon color='primary' /></IconButton>
              </CardActions>
            </Card>

          </Grid>
        </Grid>
      </Body>

    </>
  );
}
