import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { toast } from "react-hot-toast";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { channelApi } from "../../store/channel/getchannelList";
import { channelBannerEditApi } from "../../store/channel-banner/editChannelBanner";
import { channelBannerAddApi } from "../../store/channel-banner/addChannelBanner";
import { channelBannerDetailsApi, resetchannelBannerDetails } from "../../store/channel-banner/getChannelBannerDetail";
import BackdropUI from "../../ui/Backdrop";
import { DevTool } from "@hookform/devtools";

const schema = yup
  .object({
    order: yup.string().required("Please Provide Order"),
    channel: yup.number().required("Please Choose Channel Code"),
    status: yup.string().required("Please Provide Status"),
    language: yup.string().required("Please Select Language"),
    navigation: yup.string().required("Please Select Navigation"),
  })
  .required();

export default function AddBanner() {
  const splashScreenEdit = useSelector((state) => state?.channelBannerEdit);
  const splashScreenAdd = useSelector((state) => state?.channelBannerAdd);
  const uploadImage = useSelector((state) => state?.uploadImage);
  // const [page, setPage] = useState();

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });
  const [images, setImages] = React.useState([]);
  const [colour, setColour] = useState();
  const [imageLink, setImageLink] = useState();
  const channelList = useSelector((state) => state?.channelList);
  const params = useParams();
  const navigate = useNavigate();
  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      //console.log(imageList, imageList);
      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };
  const splashScreenDetails = useSelector(
    (state) => state?.channelBannerDetails
  );
  useEffect(() => {
    dispatch(channelApi());
    if (params.id) {
      dispatch(channelBannerDetailsApi(params.id));
    }
    return () => {
      dispatch(resetchannelBannerDetails());
    }
  }, [dispatch, params.id]);
  const handleUpdateForm = async (value) => {
    var newObj;

    newObj = {
      ...value,
      published: value.status,
      page_key: value.page,
      order: value.order,
      navigation: value.navigation,
    };
    if (imageLink) {
      newObj = { ...newObj, banner: imageLink };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...newObj, banner: uploadImage.result };
    } else {
      toast.error("Please Upload An Banner", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (params.id) {
      newObj = { ...newObj, id: params.id };
      await dispatch(channelBannerEditApi(newObj));
      if (!splashScreenEdit?.loading) {
        navigate("/channel-banner");
      }
    } else {
      await dispatch(channelBannerAddApi(newObj));
      if (!splashScreenAdd?.loading) {
        navigate("/channel-banner");
      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    defalutValues["link"] = splashScreenDetails.result?.link;
    defalutValues["language"] = splashScreenDetails.result?.language;
    defalutValues["order"] = splashScreenDetails.result?.order;
    defalutValues["page"] = splashScreenDetails.result?.page_key;
    defalutValues["channel"] = splashScreenDetails.result?.channel;
    defalutValues["navigation"] = splashScreenDetails.result?.navigation;
    defalutValues["status"] = `${splashScreenDetails.result?.published}`;
    reset(defalutValues);
  }, [splashScreenDetails, reset]);

  useEffect(() => {
    if (splashScreenDetails.result?.banner) {
      setImageLink(splashScreenDetails.result?.banner);
    }
    if (splashScreenDetails.result?.bg_color != null) {
      setColour(splashScreenDetails.result?.bg_color);
    }

  }, [splashScreenDetails]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-order" shrink>
                          Order
                        </InputLabel>
                        <Select
                          {...register("order")}
                          label="Order"
                          notched
                          id="outlined-adornment-order"
                          value={watch('order', "")}
                          error={!!errors?.order?.message}
                        >
                          <MenuItem value={"1"} key={1}>
                            1
                          </MenuItem>
                          <MenuItem value={"2"} key={2}>
                            2
                          </MenuItem>
                          <MenuItem value={"3"} key={3}>
                            3
                          </MenuItem>
                          <MenuItem value={"4"} key={4}>
                            4
                          </MenuItem>
                          <MenuItem value={"5"} key={5}>
                            5
                          </MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.order?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-status" shrink>
                          Status
                        </InputLabel>
                        <Select
                          {...register("status")}
                          label="Status"
                          notched
                          id="outlined-adornment-status"
                          error={!!errors?.status?.message}
                          value={watch('status', "")}
                        >
                          <MenuItem value={"true"}>Active</MenuItem>
                          <MenuItem value={"false"}>InActive</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.status?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Channel Code
                        </InputLabel>
                        <Select
                          {...register("channel")}
                          label="Channel Code"
                          id="outlined-adornment-category"
                          notched
                          disabled={params.id ? true : false}
                          error={!!errors?.channel?.message}
                          value={watch('channel', "")}
                        >
                          {channelList?.result?.result?.map((data, index) => (
                            <MenuItem key={`${index}-${data?.assign_channel_code}`} value={data.id}>{data?.channel_name}</MenuItem>
                          ))}
                        </Select>

                        <FormHelperText error>
                          {errors?.channel?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Navigation
                        </InputLabel>
                        <Select
                          {...register("navigation")}
                          label="Navigation"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.navigation?.message}
                          value={watch('navigation', "")}
                        >
                          <MenuItem value={"inapp"}>In App</MenuItem>
                          <MenuItem value={"outside"}>Outside</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.navigation?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Link</InputLabel>
                        <OutlinedInput
                          {...register("link")}
                          label="Link"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.link?.message}
                          value={watch('link', "")}
                        />
                        <FormHelperText error>
                          {errors?.link?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={watch('language', "")}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Page
                        </InputLabel>
                        <Select
                          {...register("page")}
                          label="Page"
                          id="outlined-adornment-category"
                          notched
                          error={!!errors?.page?.message}
                          value={watch('page', "")}
                        >
                          <MenuItem value={"Home Web"}>Home Web</MenuItem>
                          <MenuItem value={"Home Mobile"}>Home Mobile</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.page?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                      </FormControl>
                    </Stack>
                  </>

                  <InputLabel>
                    Banner Image
                  </InputLabel>
                  {imageLink ? (
                    <div className="image-item">
                      <img
                        src={imageLink}
                        alt=""
                        width={watch('channel_code', "") === "Splash" ? 200 : 320}
                        height={watch('channel_code', "") === "Splash" ? 200 : 180}
                      />
                      <div className="image-item__btn-wrapper">
                        <Button
                          onClick={() => setImageLink(null)}
                          variant="contained"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <ImageUploading
                      acceptType={['png', 'jpeg', 'jpg']}
                      value={images}
                      onChange={onUploadImage}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div className="upload__image-wrapper">
                            {imageList.length > 0 ? null : (
                              <Button
                                type="button"
                                variant="contained"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop Image here
                              </Button>
                            )}

                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution
                                  </span>
                                )}
                              </div>
                            )}
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width={watch('channel_code', "") === "Splash" ? 200 : 320}
                                  height={watch('channel_code', "") === "Splash" ? 200 : 180}
                                />
                                <div className="image-item__btn-wrapper">
                                  <Button
                                    onClick={() => onImageUpdate(index)}
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    onClick={() => onImageRemove(index)}
                                    variant="contained"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  )}
                </Stack>
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/channel-banner")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={splashScreenEdit?.loading || splashScreenAdd?.loading} />
      <DevTool control={control} />
    </>
  );
}
